@import "variables";
@import "mixins";

.yesLabel {
	margin-left: 2%;
}

.yesRadio {
	margin-right: 25%;
}

.fullScreen {
	@include dashBoxShadows;
	@include roundCorners;
	padding: 10px;
}

.greenText {
	color: $main-green;
}

.cursorPoint {
	cursor: pointer;
}

.removeButton {
	button {
		background: none;
		border: none;
		padding: 0;
		font: inherit;
		outline: inherit;
		font-size: small;
		color: $red;
	}

	float: right;
}

.noContentToShow {
	float: none !important;
	text-align: center;
	font-weight: 700;
	span {
		display: block;
		width: 20px;
		height: 2px;
		background-color: $black;
		border-radius: 2px;
		margin: 20px auto;
	}
	a {
		display: block;
		width: 150px;
		margin: 10px auto;
		background-color: $main-green;
		color: $white;
		border-radius: 10px;
		padding: 5px 10px;
	}
}

.hidden {
	display: none;
}

.popUpReservedFor {
	p {
		font-weight: 700;
		font-size: large;
	}
	display: inline-block;
}

.popUpDate {
	p {
		font-size: small;
	}
}

.popUpTime {
	p {
		font-size: small;
	}
}

.popUpLocation {
	p {
		font-size: small;
	}
}

.shorterTimeButton {
	margin-top: 0.5%;
	button {
		background: none;
		border: none;
		padding: 0;
		font: inherit;
		outline: inherit;
		font-size: small;
		height: 20px;
		color: $main-blue;
	}
	float: right;
}

.bareButton {
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
}

.icon {
	height: 35px;
	width: 35px;
}

.xicon {
	width: 25px;
	height: 25px;
}

.invertedBtn {
	border: $black solid 1px;
	color: $black;
	background-color: $white;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	padding: 0.4em 0.65em;
	font-size: 1.1em;
	line-height: 1.5;
	border-radius: 0.25em;
	font-family: inherit;
	margin-right: 5px;
	a {
		color: $black;
		transition: color 0.5s;
	}
	transition: color 0.2s, background-color 0.2s;
}

.invertedBtn:hover {
	border: $black solid 1px;
	color: $white;
	background-color: $black;
	a {
		color: $white;
	}
}

.activated {
	@include greenButton;
}

.deactivated {
	@include greenButton;
	background-color: gray;
	cursor: default;
	pointer-events: none;
}

//--------------------
//Loading icon control

/*
<div class='loading'>
  <img src="images/BlueLogoClearBackground.png" alt="Loading..." class="loadingImg">
</div>
*/

.loading {
	border: 8px solid $lightGrey; /* Light grey */
	border-top: 8px solid $main-blue; /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}

.loadingImg {
	width: 120px;
	height: 120px;
	animation: backSpin 2s linear infinite;
}

@keyframes backSpin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

//Loading icon control
//--------------------

@media (max-width: 1150px) {
	.invertedBtn {
		padding: 0.2em 0.35em;
	}
}
