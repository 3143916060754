$main-green: #58ba5a;
$main-blue: #04c0df;
$red: #F22525;
$grey: #e7e7e7;
$lightGrey: #f3f3f3;
$drop-shadow: #dfdfdf;
$white: #FFFFFF;
$black: #505050;

/*
400
500
600
700
*/

$fontWeightNormal: 400px;
$fontWeightSemiBold: 600px;
$fontWeightBold: 700px;

$dashNavWidth: 75px;
$cornerRadius: 10px;
$pfpRadius: 140px;