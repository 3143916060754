@import 'variables';
@import 'mixins'; 

#onboardForm {
    textarea {
        width: 98%;
        max-width: 98%;
    }
    label:nth-of-type(1) {
        @include sloppyFix;
    }
    label:nth-of-type(2) {
        @include sloppyFix;
    }
    label:nth-last-of-type(1) {
        @include sloppyFix;
    }
}