@import "../../scss/variables";

.fc {
	height: 100%;

	a {
		color: $black;
		font-size: 0.75em;
	}

	.fc-day-past {
		.fc-daygrid-event-dot {
			border-color: $black !important;
		}
	}

	tr {
		.fc-day-sat,
		.fc-day-sun {
			background-color: $lightGrey;
			position: relative;
			background-clip: padding-box;
		}
	}

	.fc-button-active,
	.fc-button-primary:disabled {
		background-color: $white !important;
		color: $black !important;
	}

	.fc-dayGridMonth-button,
	.fc-dayGridWeek-button,
	.fc-dayGridDay-button,
	.fc-next-button,
	.fc-prev-button,
	.fc-today-button {
		background-color: $black;
		border-color: $black;
	}

	.fc-dayGridMonth-button:hover,
	.fc-dayGridWeek-button:hover,
	.fc-dayGridDay-button:hover,
	.fc-next-button:hover,
	.fc-prev-button:hover,
	.fc-today-button:hover {
		background-color: $black;
		border-color: $black;
		filter: contrast(115%);
	}
	.fc-findLessons-button,
	.fc-shareAvailability-button {
		background-color: $main-green;
		border-color: $main-green;
	}

	.fc-findLessons-button:hover,
	.fc-shareAvailability-button:hover {
		background-color: $main-green;
		border-color: $main-green;
		filter: contrast(115%);
	}
}

@media (max-width: 905px) {
	.fc-toolbar {
		flex-wrap: wrap;
		div:nth-of-type(2) {
			order: -1;
			width: 100%;
		}
	}
}

@media (max-width: 700px) {
	.fc-toolbar {
		div {
			display: flex;
			flex-wrap: wrap;
		}
		div:nth-of-type(1) > button,
		div:nth-of-type(3) > button {
			order: -1;
			width: 100%;
		}
		div:nth-of-type(1) > button,
		div:nth-of-type(3) > button,
		div:nth-of-type(1) > div,
		div:nth-of-type(3) > div {
			margin: 0px !important;
			font-size: 0.8rem;
		}

		div:nth-of-type(1) {
			width: 135px;
			div {
				margin-top: 5px !important;
				width: auto;
			}
		}

		div:nth-of-type(3) {
			width: 135px;
			div {
				margin-top: 5px !important;
				width: 100%;
			}
		}

		div:nth-of-type(1) > div {
			button {
				width: 50%;
			}
		}
		div:nth-of-type(3) > div {
			justify-self: end;
			button {
				width: 33%;
			}
		}
	}

	.fc-daygrid-event-dot {
		display: none;
	}

	.fc-event-time {
		font-size: 10px;
	}
}

/*

#calendar {
    a {
        color: $black;
        font-size: 0.75rem;
    }
    .fc-event-title {
        font-size: 0.75rem;
    }
    .fc-button-primary {
        background-color: $black;
        border: none;
    }
    .fc-button-primary:hover {
        filter: contrast(115%);
    }
    .fc-addAvailabilityButton-button, .fc-shareAvailability-button, .fc-findLessons-button {
        background-color: $main-green;
    }
    .fc-daygrid-more-link {
        font-size: 0.5rem;
    }

}
}
*/
