@import 'variables';
@import 'mixins';

#isParentForm {
    @include dashBoxShadows;
    @include roundCorners;
    width: 350px;
    margin: auto;

    padding: 20px 20px 10px;

    text-align: center;
    span {
        margin: 20px;
    }
}

#parentChooseStudent {
    @include dashBoxShadows;
    @include roundCorners;
    width: 500px;
    margin: auto;

    padding: 20px;
}

#termsForm {
    @include dashBoxShadows;
    @include roundCorners;
    width: 350px;
    margin: auto;

    position: relative;
    top: 60px;

    padding: 20px 20px 10px;

    text-align: center;
    span {
        margin: 20px;
    }

    a, h3 {
        font-size: 1.25rem;
    }
}