@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";

.ElementsApp .is-focused {
	.Input.is-focused {
		color: green;
	}
}

.fullScreenAdjusted#paymentPage {
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	padding-bottom: 0;
	overflow-y: hidden;
	display: block;
	flex-direction: row;
}

.loadingSection {
	height: 60%;

	.loadingContainer {
		height: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;

		.loading {
			margin: 0 auto;
		}

		.loadingImg,
		.loading {
			width: 5em;
			height: 5em;
		}
	}
}

.left {
	width: 25%;
	margin: 10px 10px 10px 0px;
	border-right: solid $black 2px;

	hr {
		height: 0.005px;
		color: #707070;
		margin: 10px 15px;
	}

	h2 {
		padding-left: 10px;
		padding-top: 10px;
	}

	#cartList {
		.cartItem {
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 7px;
			line-height: 120%;

			.cartInstructorName {
				font-weight: 700;
				display: inline-block;
			}

			.itemPrice {
				font-weight: 700;
				float: right;
			}

			.itemDate {
				p {
					font-size: small;
				}
			}

			.itemTime {
				p {
					font-size: small;
				}

				display: inline-block;
				padding-bottom: 7px;
			}
		}
	}

	#cartTotal {
		padding-top: 5px;

		p {
			text-align: center;
			font-weight: 700;
		}
	}

	#emptyCart {
		padding-top: 5px;

		p {
			text-align: center;
		}

		button {
			@include greenButton;
			margin: 0 auto;
			display: block;
			margin-top: 2%;

			a {
				color: $white;
			}
		}
	}
}

.right {
	width: 73%;
	height: 92%;

	.stepsDiv {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 25%;
		width: 100%;
		margin: 0 auto;

		.individualStep {
			display: inline-block;
			text-align: center;
			width: 52px;

			.circleDiv {
				height: 50px;
				width: 50px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 700;
				border: 1px solid;
				text-align: center;
				border: 1px solid #e6e6e6;
				box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);

				p {
					font-size: 25px;
				}

				img {
					height: 20px;
					width: 20px;
				}
			}

			.textUnderStep {
				text-align: center;

				p {
					text-align: center;
				}
			}
		}

		.stepSeparator {
			display: inline-block;
			width: 10%;
			margin-left: 7%;
			margin-right: 7%;
			padding-bottom: 2%;
		}

		.complete {
			background-color: $main-green;
		}

		.currentlyOn {
			background-color: $main-blue;

			p {
				color: white;
			}
		}

		.notComplete {
			background-color: white;

			p {
				color: black;
			}
		}
	}

	#paymentFormContainer {
		height: 100%;
		#paymentForm {
			width: 90%;
			height: max-content;
			margin: 0 auto;
			display: block;

			.viewCartBtn {
				display: none;
				width: 100%;
			}

			#paymentDiv {
				height: 100%;
				padding-bottom: 10%;

				#paymentInputs {
					overflow-y: auto;
					max-height: 75%;
					padding: 0 1% 5% 1%;

					#cardButtons {
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 3% 0;

						button {
							background: none;
							width: 215px;
							border: 1px solid #e6e6e6;
							box-shadow: 0px 1px 1px rgb(0 0 0 / 3%),
								0px 3px 6px rgb(0 0 0 / 2%);
							padding: 0;
							font: inherit;
							outline: inherit;
							margin-left: 1%;
							margin-right: 1%;
							padding-top: 1%;
							padding-bottom: 1%;
							border-radius: 15px;
							color: $black;
						}

						.selected {
							background-color: $main-blue;
							color: white;
						}
					}

					#saveCardDiv {
						margin-top: 30px;
						display: block;
						width: 100%;
					}

					#newCardInputs {
						#inputs {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							width: 97%;

							//padding-left: 3%;
							div {
								margin-bottom: 2%;
								width: 100%;

								.divInput {
									box-shadow: 0px 1px 1px rgb(0 0 0 / 3%),
										0px 3px 6px rgb(0 0 0 / 2%);
									padding: 10px 5px;
									@include roundCorners;
									width: 100%;
								}

								div {
									transition: all 0.5s ease;
									border: 1px solid #e6e6e6;
								}

								div.focused {
									border-color: $main-blue;
								}
							}
							div.shortInputContainer {
								display: flex;
								flex-wrap: wrap;
								justify-content: space-between;
								border: 0;
								div.shortInput {
									width: 40%;
									border: none;

									.divInput {
										box-shadow: 0px 1px 1px rgb(0 0 0 / 3%),
											0px 3px 6px rgb(0 0 0 / 2%);
									}

									div {
										width: 100%;
										transition: all 0.5s ease;
										border: 1px solid #e6e6e6;
									}

									div.focused {
										border-color: $main-blue;
									}
								}
							}
						}
					}

					#existingCardInputs {
						display: flex;
						height: 82%;
						padding-bottom: 20px;
						justify-content: center;

						.centerContent {
							background-color: $white;
							border-radius: 15px;
							border: 1px solid #e6e6e6;
							height: 75%;
							width: 75%;
							padding: 20px 20px 20px 20px;
							overflow-y: auto;
							box-shadow: 0px 1px 1px rgb(0 0 0 / 3%),
								0px 3px 6px rgb(0 0 0 / 2%);
						}

						li {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-bottom: 2%;
							border-radius: 7px;
							padding: 1%;

							input {
								margin: 0 0 0 0;
							}

							label {
								width: 100%;
								padding-left: 10%;
								padding-right: 5%;
								display: inline-flex;
								flex-flow: row nowrap;
								justify-content: space-between;
								align-items: center;

								img {
									height: 40px;
									width: 40px;
								}

								.cardNum {
									padding-left: 3%;
								}

								.expDate {
									margin-left: 40%;
								}
							}
						}

						li.selected {
							background-color: #04c2df2c;
						}
					}
				}

				#paymentNext {
					float: right;
					margin-top: 3%;
					margin-bottom: 0px;
					padding-left: 30px;
					padding-right: 30px;
				}
			}

			#billingDiv {
				height: 100%;

				#billingInputs {
					overflow-y: scroll;
					height: 75%;

					input {
						width: 100%;
						transition: all 0.5s ease;
						border: 1px solid #e6e6e6;
					}

					input:focus {
						border: 1px solid $main-blue;
						outline: none;
					}

					.formGroup {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						width: 100%;

						li {
							margin-bottom: 1%;
							width: 100%;

							input {
								width: 100%;
							}
						}

						.shortInput {
							width: 40%;

							input {
								width: 100%;
							}
						}
					}
				}

				#billingBack {
					@include greenButton;
					background-color: grey;
					margin-top: 0px;
					margin-bottom: 0px;
					padding-left: 30px;
					padding-right: 30px;
				}

				#billingNext {
					margin-top: 0px;
					margin-bottom: 0px;
					padding-left: 30px;
					padding-right: 30px;
				}
			}

			#reviewDiv {
				height: 100%;

				#reviewInfo {
					height: 75%;
					display: flex;
					justify-content: center;
					align-items: center;
					vertical-align: middle;

					.centerContent {
						background-color: $white;
						border-radius: 15px;
						border: 1px solid #e6e6e6;
						box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
						overflow-y: auto;
						max-height: 75%;
						width: 75%;
						padding: 20px 20px 20px 20px;
						margin-bottom: 1%;

						.editReview {
							color: $main-blue;
							float: right;
							margin-top: 3px;
						}

						#billingReview {
							p {
								font-weight: 400;
								font-size: small;
							}
						}

						#paymentReview {
							p {
								font-weight: 400;
								font-size: small;
							}
						}

						#totalReview {
							font-weight: 700;
							margin: 0 auto;
							display: block;
						}
					}
				}

				#reviewBack {
					@include greenButton;
					background-color: grey;
					margin-top: 0px;
					margin-bottom: 0px;
					padding-left: 30px;
					padding-right: 30px;
				}

				#reviewPurchase {
					margin-top: 0px;
					margin-bottom: 0px;
					padding-left: 30px;
					padding-right: 30px;
				}
			}
		}
	}

	#successDiv {
		height: 100%;
		align-items: center;
		justify-content: center;
		#successContent {
			#successHeader {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 100%;
				div {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					flex-grow: 2;
					width: 100%;
					img {
						width: 4em;
						margin-right: 10px;
					}
				}
				p {
					width: 100%;
					text-align: center;
				}
				strong {
					font-weight: bold;
				}
				span {
					margin: 0;
				}
			}

			#successBody {
				margin-top: 5%;
			}

			#successFooter {
				margin-top: 10%;
				display: inline-flex;
				width: 100%;
				align-items: center;
				justify-content: center;
				button {
					width: 200px;
				}
			}
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			img,
			p,
			span,
			button {
				margin: 1%;
			}
		}
	}
	#errorDiv {
		height: 90%;
		justify-content: center;
		align-items: center;
		#errorContent {
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			img {
				width: 8em;
			}
			img,
			p,
			span,
			button {
				margin: 1%;
			}
			#errorMessage {
				color: red;
			}
		}
	}
	.footerButtons {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-left: 0;
		margin-top: 3%;
		.viewCartBtn {
			width: 100%;
			margin-bottom: 4%;
		}
	}
}

.vr {
	height: 100%;
	width: 1px;
	border-left: 1.5px solid #707070;
	display: none;
}

@media (max-width: 1350px) {
	// body {
	//     color: red;
	// }
	.fullScreenAdjusted {
		overflow-y: hidden;
	}

	.left {
		display: none;
	}

	.right {
		width: 100%;

		.stepsDiv {
			height: 15%;
		}

		#paymentForm {
			.viewCartBtn {
				display: block;
				width: 100%;
			}
		}
	}
}

@media (max-width: 1150px) {
	.loadingSection {
		height: 100%;
	}

	.fullScreenAdjusted {
		overflow-y: hidden;
	}

	.right {
		.stepsDiv {
			display: none;
		}
		#paymentFormContainer {
			#paymentForm {
				height: 100%;
			}
		}

		margin: 3% 0;
	}
}

@media (max-width: 750px) {
	.right {
		#successDiv {
			#successContent {
				#successFooter {
					flex-direction: column;
					button {
						width: 90%;
						margin: 2%;
					}
				}
			}
		}
		#paymentFormContainer {
			#paymentForm {
				height: 100%;

				.footerButtons {
					margin-top: 5%;
				}

				#paymentDiv {
					#paymentInputs {
						max-height: 65%;

						#cardButtons {
							button {
								padding-top: 3%;
								padding-bottom: 3%;
							}
						}

						#existingCardInputs {
							.centerContent {
								ul li label {
									padding: 3%;
									justify-content: space-between;

									.cardNum {
										padding-left: 3%;
									}

									.expDate {
										margin: 0;
									}
								}
							}
						}

						#newCardInputs {
							width: 100%;

							#inputs {
								display: flex;
								flex-wrap: wrap;
								justify-content: space-between;

								div {
									margin: 1% 0;
								}

								div.shortInputContainer {
									div.shortInput {
										width: 100%;

										div {
											width: 100%;
										}
									}
								}

								#saveCardDiv {
									display: block;
									width: 100%;
								}
							}
						}
					}
				}

				#billingDiv {
					#billingInputs {
						height: 65%;

						.formGroup {
							li {
								margin: 2% 0;
							}

							.shortInput {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
