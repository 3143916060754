@import '../../scss/variables';

#messageListHeader {
    display: flex;

    h3 {
        margin-right: auto;
    }
}

#messageHeader+hr,
#messageListHeader+hr {
    width: calc(100% - 10px);
}

#messagesListContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    ul {
        flex-grow: 1;
        padding: 0 10px;

        span {
            li {
                white-space: pre-wrap;
            }
        }
    }
}

.messageModal {
    ::selection{
        color: $white;
        background: $main-green;
      }
      
      .wrapper{
        max-width: 450px;
        margin: 20px auto;
      }
      
      .wrapper .search-input{
        background: $white;
        width: 100%;
        border-radius: 5px;
        position: relative;
        box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.08);
      }
      
      .search-input input{
        height: 55px;
        width: 100%;
        outline: none;
        border: none;
        border-radius: 5px;
        padding: 0 30% 0 5%;
        font-size: 18px;
        box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
      }
      
      .search-input.active input{
        border-radius: 5px 5px 0 0;
      }
      
      .search-input .autocom-box{
        padding: 0;
        opacity: 1;
        pointer-events: none;
        max-height: 280px;
        overflow-y: auto;
      }
      
      .search-input.active .autocom-box{
        padding: 10px 8px;
        opacity: 1;
        pointer-events: auto;
      }
      
      .autocom-box li{
        padding: 8px 12px;
        width: 91%;
        border-radius: 3px;
        display: block;
      }
      
      .autocom-box li:hover{
        background: #efefef;
      }
      
      .search-input .icon{
        position: absolute;
        right: 0px;
        top: 0px;
        height: 55px;
        width: 20%;
        text-align: center;
        line-height: 55px;
        font-size: 20px;
        margin-left: 10px;
        background-color: $main-green;
        color: $white;
        border-radius: 5%;
      }
    
      @media (max-width: 1150px) {
        .search-input .icon {
            width: 25%;
            font-size: 15px;
          }
    
        
    }
}

#searchDiv {
    width: 80%;
    margin: 0 auto;
}

#directMessageInput {
    display: flex;
    margin-top: 10px;
    max-height: 50%;

    textarea {
        flex-grow: 1;
        margin-right: 10px;
        padding: 0;
        padding-left: 10px;
        padding-top: 10px;
        resize: none;
        max-height: calc(100% - 10px);
        overflow-y: auto !important; //Overrides imported component js styles
    }

    input[type=submit] {
        margin: auto 0 0 0;
        padding: 10px;
        font-size: 15px;
    }
}

.Conversation {
    display: flex;
    padding: 5px 0px;

    img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    div {
        display: inline-flex;
        align-items: center;
        margin-left: 20px;
        flex-grow: 1;
    }

    &+li {
        hr {
            display: block;
            width: calc(100% - 85px);
            margin-right: 0;
            opacity: 50%;
        }
    }
}

.fullScreen {
    display: flex;
    flex-direction: column;
}