@import "../../scss/variables";
@import "../../scss/mixins";

#shareInstrumentList {
	div {
		margin: 0;
	}
	margin: 10px 0px;
	.instruments,
	label {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 10px;
	}
}

#timePickerQuestion {
	overflow: visible;
}

form#newLesson {
	#dateTimeNext.deactivated {
		margin: 20px 0px;
		padding: 10px 20px;
	}

	input#repeat {
		width: 60px;
	}

	input#search {
		padding-right: 0;
	}

	#searchDiv {
		width: 80%;
		margin: 0 auto;
	}
	#offlineLessons {
		margin-top: 40px;
		display: block;
		label[for="newAddress"] {
			margin-left: 2%;
		}
		label[for="savedAddresses"] {
			margin-left: 4%;
		}
		#createAddress {
			margin-top: 40px;

			input {
				margin: 3% 0;
				padding: 1%;
				width: 50%;
			}
		}
	}

	#addressInfo {
		margin-top: 40px;
		.savedAddressDiv {
			margin-top: 30px;
		}
		input[type="radio"] {
			display: inline;
			margin-right: 50px;
			vertical-align: top;
		}
		label {
			display: inline-block;
			vertical-align: top;
		}
	}

	#confirmationhtmlForm {
		#confirmationInfo {
			.confirmHeader {
				display: inline;
			}
			.editIcon {
				display: inline;
				height: 15px;
				margin-left: 5px;
			}
		}
	}
}

.nextBackButton {
	display: flex;
	justify-content: space-between;
}

.nextButtonDiv {
	display: flex;
	justify-content: right;
}

.backButton {
	@include greenButton;
	background-color: grey;
	cursor: pointer;
}

.nextButton {
	@include greenButton;
	cursor: pointer;
}
