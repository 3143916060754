@import 'variables';
@import 'mixins';

.fullScreenAdjusted {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow-y: hidden;
}

.split {
    height: calc(100% - 20px);
    overflow-x: hidden;
    display: inline-block;
    hr {
        color: #707070;
    }
}

.footerButtons {
    display: flex;
    justify-content: space-between;
    margin: 0 3% 3% 3%;
}

.cardError {
    color: red;
    text-align: center;
}

.cartFullScreen {
    width: 100%;
    height: 90%;
    hr {
        height: 0.005px;
        color: #707070;
    }
    h2 {
        padding-left: 10px;
        padding-top: 10px;
    }
    #cartList {
        .cartItem {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 7px;
            line-height: 120%;
            .cartInstructorName {
                font-weight: 700;
                display: inline-block;
            }
            .itemPrice {
                font-weight: 700;
                float: right;
            }
            .itemDate {
                p {
                    font-size: small;
                }
            }
            .itemTime {
                p {
                    font-size: small;
                }
                display: inline-block;
                padding-bottom: 7px;
            }
        }
    }
    #cartTotal {
        padding-top: 5px;
        p{
            text-align: center;
            font-weight: 700;
        }
    }
    #emptyCart {
        height: 95%;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        div {
            p{
                display: block;
                text-align: center;
                font-weight: 700;
            }
            button{
                @include greenButton;
                margin:0 auto;
                display:block;
                margin-top: 2%;
            }
        }
    }
}

.left {
    width: 25%;
    margin: 10px 10px 10px 0px;
    border-right: solid $black 2px;
    hr {
        height: 0.005px;
        color: #707070;
        margin: 10px 15px;
    }
    h2 {
        padding-left: 10px;
        padding-top: 10px;
    }
    #cartList {
        .cartItem {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 7px;
            line-height: 120%;
            .cartInstructorName {
                font-weight: 700;
                display: inline-block;
            }
            .itemPrice {
                font-weight: 700;
                float: right;
            }
            .itemDate {
                p {
                    font-size: small;
                }
            }
            .itemTime {
                p {
                    font-size: small;
                }
                display: inline-block;
                padding-bottom: 7px;
            }
        }
    }

    #cartTotal {
        padding-top: 5px;
        p{
            text-align: center;
            font-weight: 700;
        }
    }
    #emptyCart {
        padding-top: 5px;
        p{
            text-align: center;
        }
        button{
            @include greenButton;
            margin:0 auto;
            display:block;
            margin-top: 2%;
        }
    }
}

.right {
    width: 73%;
    height: 92%;
    .stepsDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25%;
        width: 100%;
        margin: 0 auto;
        .individualStep {
            display: inline-block;
            text-align: center;
            width: 52px;
            .circleDiv {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                border: 1px solid;
                text-align: center;
                p {
                    font-size: 25px;
                }
                img {
                    height: 20px;
                    width: 20px;
                }
            }
            .textUnderStep {
                text-align: center;
                p {
                    text-align: center;
                }
            }
        }
        .stepSeparator {
            display: inline-block;
            width: 10%;
            margin-left: 7%;
            margin-right: 7%;
            padding-bottom: 2%;
        }
        .complete {
            background-color: $main-green;
        }
        .currentlyOn {
            background-color: $main-blue;
            p {
                color: white;
            }
        }
        .notComplete {
            background-color: white;
            p {
                color: black;
            }
        }
    }
    #paymentForm {
        height: 75%;
        width: 90%;
        margin: 0 auto;
        .viewCartBtn {
            display: none;
            width: 100%;
        }
        #paymentDiv {
            height: auto;
            #paymentInputs {
                overflow-y: auto;
                max-height: 75%;
                #cardButtons {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 3% 0;
                    button {
                        background: none;
                        width: 215px;
                        border: 0.5px solid black;
                        padding: 0;
                        font: inherit;
                        outline: inherit;
                        margin-left: 1%;
                        margin-right: 1%;
                        padding-top: 1%;
                        padding-bottom: 1%;
                        border-radius: 15px;
                    }
                    .selected {
                        background-color: $main-blue;
                        color: white;
                    }
                }
                #newCardInputs {
                    width: 100%;
                    #inputs {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        //padding-left: 3%;
                        div {
                            width: 100%;
                            margin-bottom: 2%;
                        }
                        div.shortInput {
                            width: 40%;
                            div {
                                width: 100%;
                            }
                        }
                        #saveCardDiv {
                            display: block;
                            width: 100%;
                        }
                    }
                }
                
                #existingCardInputs {
                    height: 82%;
                    padding-bottom: 20px;
                    .centerContent {
                        background-color: #F7F7F7;
                        border-radius: 15px;
                        border: 1px solid black;
                        height: 75%;
                        width: 75%;
                        padding: 20px 20px 20px 20px;
                        overflow-y: auto;
                    }
                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 2%;
                        input {
                            margin: 0 0 0 0;
                        }
                        label {
                            width: 100%;
                            padding-left: 10%;
                            padding-right: 5%;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            justify-content: space-between;
                            align-items: center;
                            img {
                                height: 40px;
                                width: 40px;
                            }
                            .cardNum {
                                    padding-left: 3%;
                            }
                            .expDate {
                                margin-left: 40%;
                            }
                        }
                    }
                }
            }
            #paymentNext {
                float: right;
                margin-top: 3%;
                margin-bottom: 0px;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        #billingDiv {
            height: 100%;
            #billingInputs {
                overflow-y: auto;
                height: 75%;
                input {
                    display: block;
                    margin-bottom: 2%;
                }
                .formGroup {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    li {
                        margin-bottom: 1%;
                        width: 100%;
                        input {
                            width: 100%;
                        }
                    }
                    .shortInput {
                        width: 40%;
                        input {
                            width: 100%;
                        }
                    }
                }
            }
            #billingBack {
                @include greenButton;
                background-color: grey;
                margin-top: 0px;
                margin-bottom: 0px;
                padding-left: 30px;
                padding-right: 30px;
            }
            #billingNext {
                margin-top: 0px;
                margin-bottom: 0px;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        #reviewDiv {
            height: 100%;
            #reviewInfo {
                height: 75%;
                display: flex;
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                .centerContent {
                    background-color: #F7F7F7;
                    border-radius: 15px;
                    border: 1px solid black;
                    overflow-y: auto;
                    max-height: 75%;
                    width: 75%;
                    padding: 20px 20px 20px 20px;
                    margin-bottom: 1%;
                    .editReview {
                        color: $main-blue;
                        float: right;
                        margin-top: 3px;
                    }
                    #billingReview {
                        p {
                            font-weight: 400;
                            font-size: small;
                        }
                    }
                    #paymentReview {
                        p {
                            font-weight: 400;
                            font-size: small;
                        }
                    }
                    #totalReview {
                        font-weight: 700;
                        margin:0 auto;
                        display:block;
                    }
                }
            }
            #reviewBack {
                @include greenButton;
                background-color: grey;
                margin-top: 0px;
                margin-bottom: 0px;
                padding-left: 30px;
                padding-right: 30px;
            }
            #reviewPurchase {
                margin-top: 0px;
                margin-bottom: 0px;
                padding-left: 30px;
                padding-right: 30px;
            }
        }

        #successDiv, #errorDiv {
            height: 90%;
            display: none;
            justify-content: center;
            align-items: center;
            #successContent, #errorContent {
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                img {
                    width: 8em;
                }
                img, p, span, button {
                    margin: 1%;
                }
                #errorMessage {
                    color: red;
                }
            }
        }
        .footerButtons {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-left: 0;
            margin-top: 3%;
            .viewCartBtn {
                width: 100%;
                margin-bottom: 4%;
            }

        }
    }
}

.vr {
    height: 100%;
    width: 1px;
    border-left: 1.5px solid #707070;
    display: none;
}



#findLessonContainer {
    position: relative;
    #findLesson {
        display: block;
        background-attachment: black;
        margin-bottom: 5px;
    }
}

#availableDiv {
    line-height: 10%;
    #start, #end {
        margin-right: 5px;
        border-radius: 10px;
        border-width: 1px;
        width: 15%;
    }
    #availableInfo {
        margin-bottom: 5%;
        .popUpTime {
            display: inline-block;
            padding-bottom: 7px;
        }
    }
    #availableLocation {
        margin-bottom: 5%;
        #availableLocationHeader {
            font-weight: bold;
            font-size: large;
        }
    }
    #availableInstruments {
        #availableInstrumentsHeader {
            font-weight: bold;
            font-size: large;
        }
        li {
            display: flex;
            align-items: center;
            input {
                height: 10px;
                width: 10px;
                margin: 0 5px 0 0;
            }
            label {
                font-size: small;
            }
        }
    }
    #addToCart {
        float: right;
        margin-right: 2%;
        margin-bottom: 0px;
    }
}

@media (max-width: 1350px) {
    // body {
    //     color: red;
    // }
    .left{
        display: none;
    }
    .right {
        width: 100%;
        .stepsDiv {
            height: 15%;
        }
        #paymentForm {
            .viewCartBtn {
                display: block;
                width: 100%;
            }
        }
    }

}

@media (max-width: 1150px) {
    .right {
        .stepsDiv {
            display: none;
        }
        margin: 3% 0;
    }
}

@media (max-width: 750px) {
    .right {
        #paymentForm {
            height: auto;
            .footerButtons {
                margin-top: 5%;
            }
            #paymentDiv {
                #paymentInputs {
                    max-height: 65%;
                    #cardButtons {
                        button {
                            padding-top: 3%;
                            padding-bottom: 3%;
                        }
                    }
                    #existingCardInputs {
                        .centerContent {
                            ul li label {
                                padding: 3%;
                                justify-content: space-between;
                                .cardNum {
                                    padding-left: 3%;
                                }
                                .expDate {
                                    margin: 0;
                                }
                            }
                        }
                    }
                    #newCardInputs {
                        width: 100%;
                        #inputs {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            div {
                                margin: 1% 0;
                            }
                            div.shortInput {
                                width: 100%;
                                div {
                                    width: 100%;
                                }
                            }
                            #saveCardDiv {
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                }
            }
            #billingDiv {
                #billingInputs {
                    height: 65%;
                    .formGroup {
                        li {
                            margin: 2% 0;
                        }
                        .shortInput {
                            width: 100%;
                        }
                    }
                }
            } 

        }
        
    }
}
