@import 'variables';
@import 'mixins'; 

#messageForm {
    textarea {
        width: 98%;
        max-width: 98%;
    }
}

#messageList {
    .message{
        width: 98%;
        max-width: 98%;
        overflow-wrap: break-word;
    }
}

#comments {
    overflow-y: auto;
    margin-bottom: 80px;
}

#newMessageContainer {
    #userMessageSearch {
        width: 65%;
    }
    .messageLi {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 5px;
        margin-top: 5px;
    }
}

hr.inbetweenMessages {
    opacity: 0.3;
}

#messagesHeader {
    display: inline;
}

#messagingScreen {
    position: relative;
    display: flex;
    flex-direction: column;

    justify-content: space-between;

    form {
        position: absolute;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px auto;
        width: calc(100% - 20px);
        textarea {
            display: inline-block;
            width: calc(100% - 100px);
            height: 40px;
            margin-right: 10px;
            resize: none;
        }
        button {
            width: 80px;
            height: 50px;
            margin: 10px;
            @include greenButton;
        }
    }
}

@media (max-width: 575px) {
    #messageListScreen {
        position: relative;
        #messagesHeader {
            margin-bottom: 5px;
            display: block;
        }
    }
}

@media (max-width: 1150px) {
    #messagingScreen, #newMessageScreen, #messageListScreen {
        @include dashBoxShadows;
        @include roundCorners;
        margin: 15px auto;
        padding: 10px;
    }
}