@import "../../scss/variables";
@import "../../scss/mixins";

.deleteLessons {
	@include greenButton;
	background-color: $white;
	color: red;
	margin: 0;
	padding: 10px;
	margin: auto;
	display: block;
}

.detailHeader {
	margin-top: 20px;
	font-weight: 700;
	font-size: 20px;
}

button.addNotes {
	@include greenButton;
	float: right;
}

.meetingLink,
#editAvailable {
	@include greenButton;
	button,
	a {
		color: $white;
		display: block;
		text-align: center;
	}
}

#editAvailable {
	width: 100%;
}

#lessonDetails {
	p {
		line-height: 1.45;
		margin-right: 5px;
	}

	#lessonPrice {
		margin-top: 1%;
		font-weight: 700;
		float: right;
	}
}
