@import 'variables';
@import 'mixins';

#settingsMain, #settingsNotif {
    input[type=submit] {
        display: block;
        margin: 20px 0px 0px auto;

    }
}

#settingsMain {
    form {
        label:nth-of-type(1) {
            @include sloppyFix;
        }
        label:nth-of-type(2) {
            @include sloppyFix;
        }
        label:nth-of-type(3) {
            @include sloppyFix;
        }
        label:nth-of-type(4) {
            @include sloppyFix;
        }
        label:nth-of-type(5) {
            @include sloppyFix;
        }
    }
    a {
        display: block;
        margin-top: 10px;
    }
    textarea {
        width: 100%;
        min-height: 80px;
    }
    input:nth-of-type(1), input:nth-of-type(2) {
        margin: 5px 0px;
    }
    h2 {
        display: inline-block;
    }
    #displayEmail, #displayEmail+label {
        margin-top: 25px;
    }
}

.settingsHeader {
    img {
        height: 100px;
        width: 100px;
        border: solid $black 2px;
        border-radius: $pfpRadius;

        object-fit: cover;
    }
    img, h2 {
        display: inline-block;
        vertical-align: middle;;
    }
}

.sr-main {
    text-align: center;
    @include roundCorners;
    @include dashBoxShadows;
    height: 200px;
}

#settingsMain {
    padding: 10px;
    h2 {
        margin-bottom: 10px;
    }
}

#settingsNotif {
    padding: 10px;
}

#newCardLink {
    @include greenButton;
    position: absolute;
     top: 5px;
    right: 10px;
}

@media (max-width: 1150px) {
    #settingsMain, #settingsNotif {
        @include dashBoxShadows;
        @include roundCorners;
        margin: 15px auto;
    }
}