.xImg {
	width: 30px;
	height: 30px;
	float: right;
	cursor: pointer;
	color: red;
	margin-top: 5px;
	background-image: url("../../../images/x-icon.png");
	background-size: cover;
}

@media (max-width: 575px), (max-height: 575px) {
	.xImg {
		width: 20px;
		height: 20px;
	}
}
