@import '../../scss/variables';
@import '../../scss/mixins'; 
@import '../../scss/grid';

.noContentToShow {
    margin: 20px 0px;
}

#historyHeading {
    display: inline-block;
}

@media (max-width: 1150px) {
    #recentLessons, #recentStudents, #paymentHistory, #recentInstructors {
        @include dashBoxShadows;
        @include roundCorners;
        margin: 15px auto;
        padding: 10px;
    }
}