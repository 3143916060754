#termsAndConditionsModal {
	text-align: center;

	#termsAndConditionsForm {
		padding-bottom: 10px;

		hr {
			margin-left: 0;
			width: 100%;
		}

		input,
		label {
			cursor: pointer;
			margin: 20px auto 0;
		}
	}
}
