.timePickerInputs {
    font-size: small;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    input {
        flex-grow: 1;
        width: 20%;
        margin: 0;
        text-align: center;
    }
}