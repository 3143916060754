@import '../../../../scss/variables';

.cartItem {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    line-height: 120%;
    .cartInstructorName {
        font-weight: 700;
        display: inline-block;
    }
    .itemPrice {
        font-weight: 700;
        float: right;
    }
    .itemDate {
        p {
            font-size: small;
        }
    }
    .itemTime {
        p {
            font-size: small;
        }
        display: inline-block;
        padding-bottom: 7px;
    }
}