@import "../../scss/variables";
@import "../../scss/mixins";

#instructorOnboardForm {
	.questionDiv {
		margin: 10px 0;
		.question {
			margin: 5px 0;
		}
		textarea {
			margin: 5px 0;
			resize: none;
		}
		button#selectInstrumentsButton {
			margin: 0 !important;
			cursor: pointer;
			@include greenButton;
		}
	}
	.nextButtonDiv {
		button.activated {
			cursor: pointer;
		}
	}
}

div#onboardSuccessMessage {
	text-align: center;
	#continueButton {
		cursor: pointer;
		margin: 20px auto;
	}
}
