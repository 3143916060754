@import "../../scss/variables";
@import "../../scss/mixins";

.overlay {
	position: fixed; /* Sit on top of the page content */
	display: flex; /* Hidden by default */
	width: 100vw; /* Full width (cover the whole page) */
	height: 100vh; /* Full height (cover the whole page) */

	justify-content: center;
	align-items: center;

	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
	z-index: 100000; /* Specify a stack order in case you're using a different order for other elements */
	.overlayContent {
		@include roundCorners;
		position: absolute;
		width: 100vw;
		max-width: 625px;
		max-height: calc(100% - 80px);
		min-height: 20vh;
		padding: 20px;
		font-size: 2.5rem;
		background-color: white;
		overflow-y: auto;
	}
}

@media (max-width: 750px) {
	.overlay {
		.overlayContent {
			width: 85vw;
		}
	}
}
