@import 'variables';

@mixin sloppyFix {
  display: block;
  font-weight: 700;
  margin-top: 20px;
}

@mixin centerObject {
    display: flex;
    justify-content: center; //Centers horizontal
    align-items: center; //Centers Vertical
    text-align: center; //Centers Vertical
  }

  @mixin textGradient {
    background-image: linear-gradient(45deg, $main-green, $main-blue);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  @mixin dashBoxShadows {
    background-color: $white;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);
  }

  @mixin BGGradient {
    background-image: linear-gradient(to right, $main-blue, $main-green);
  }

  @mixin roundCorners {
    border-radius: 10px;
  }

  @mixin fill {
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  @mixin greenButton {
    -webkit-appearance: none; //Fix those iphone shenanigans hopefully
    -moz-appearance: none;

    background-color: $main-green;
    color: $white;
    padding: 10px 20px;
    margin: 20px 0px;
    font-weight: 700;
    border: none;
    @include roundCorners();
  }

  @mixin specialList {
    background-color: $drop-shadow;
    padding: 10px 35px;
    border-top: solid 2px $main-blue;
    border-bottom: solid 2px $main-green;
    list-style-type: disc;
  }