@import '../../../scss/variables';
@import '../../../scss/mixins';

.timeList {
    overflow: hidden;
    overflow-y: scroll;
    text-align: center;
    flex-grow: 1;
    width: 20%;
    max-height: 150px;
    margin: 0;
    
    li.selected {
        background-color: $lightGrey;
    }
}