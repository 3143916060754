@import '../../scss/variables';
@import '../../scss/mixins';

.timePicker {
    position:relative;
    display: inline-block;
    width: 30%;
    max-width: calc(100% - 15px);
    padding: 0;
    margin: 0;
}