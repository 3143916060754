@import "../../../scss/variables";

#instructorList {
	display: flex;
	flex-wrap: wrap;
	overflow-y: auto;
	a {
		color: $black;
		display: inline-block;
		li {
			width: 220px;
			background-color: $grey;
			padding: 10px;
			margin: 10px;
			border-radius: 10px;
			img {
				display: block;
				width: 140px;
				height: 140px;
				border: solid $black 2px;
				border-radius: 140px;
				margin: auto;

				object-fit: cover;
			}
			p:nth-of-type(1),
			p:nth-of-type(2),
			p:nth-of-type(3) {
				font-weight: 700;
				text-align: center;
			}
			.noLessonAlert {
				color: red;
			}
			.lessonAlert {
				color: $main-green;
			}

			div {
				margin-top: 5px;
				height: 160px;
				overflow: hidden;
				ul,
				li {
					width: auto;
					list-style: disc;
					margin: 0 0 0 10px;
					padding: 0;
				}
			}
		}
	}
}

#searchForInstructor {
	display: block;
	width: 100%;
	margin: 15px 0px;
}

@media (max-width: 650px) {
	#instructorList {
		height: 90%;
		a {
			width: 100%;
			li {
				width: auto;
				br {
					display: none;
				}
				img {
					margin: 10px auto;
					width: 120px;
					height: 120px;
				}
				p:nth-of-type(3) {
					height: auto;
					max-height: 120px;
				}
				div {
					height: auto;
				}
			}
		}
	}
}
