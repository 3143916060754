@import "../../../scss/variables";
@import "../../../scss/mixins";

#instDashHeader {
	display: flex;
	align-items: center; //Centers Vertical
	@include BGGradient;
	color: $white;
	h2 {
		color: $white;
	}
	#instWelcome {
		margin-right: auto;
	}
	#miniStats {
		text-align: center;
	}
}

#mainPFP {
	justify-self: flex-start;

	img {
		height: $pfpRadius;
		width: $pfpRadius;

		object-fit: cover;
	}
}

@media (max-width: 1150px) {
	#instDashHeader {
		border-radius: $cornerRadius;
		display: flex;
		padding: 10px;
		#instWelcome {
			display: inline-block;
		}
		#miniStats {
			#addAvailability,
			#newMessage,
			#manageFiles {
				P {
					display: inline-block;
				}
			}
		}
	}
}

@media (max-width: 750px) {
	#instDashHeader {
		display: block;
		#mainPFP {
			img {
				width: 85px;
				height: 85px;
			}
		}
	}
	#miniStats {
		min-width: 150px;
		margin: auto;
		#addAvailability,
		#newMessage,
		#manageFiles {
			text-align: center;
			width: 94%;
			margin: 10px auto;
			min-width: 150px;
		}
	}
}

@media (max-width: 450px) {
	#mainPFP {
		display: none;
	}
	#miniStats {
		opacity: 93%;
	}
}
