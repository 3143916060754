@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";

#shareFileName {
	font-size: 20px;
	position: relative;
	top: 10px;
	img {
		position: relative;
		top: 10px;
	}
}

hr {
	width: 99%;
}

#fileContainer > h2 {
	display: inline-block;
	position: absolute;
	margin-right: 40px;
}

#fileList {
	button {
		color: $black !important; //This might just be an ios 15 error
	}
	hr {
		// background-color: #f6f6f6;
		background-color: #b7b7b7;
		color: #f6f6f6;
		height: 1px;
		border: none;
		// width: 99%;
	}
}

@media (max-width: 370px) {
	#fileContainer > h2 {
		position: static;
	}
}

.individualFiles {
	// @include centerText;
	@include centerObject;

	padding: 3px 0px 0px;
	p:nth-of-type(1) {
		position: relative;
		top: -8px;
		justify-self: left;
		margin-right: auto;
		button > img {
			position: relative;
			top: 10px;
			margin-right: 10px;
		}
	}
	.sharerInfo {
		position: relative;
		margin-right: 20px;
		justify-self: left;
		align-items: center;
		vertical-align: middle;
		img {
			display: inline-block;
			width: 45px;
			height: 45px;
			border: solid $black 2px;
			border-radius: 140px;
			margin: 0px 10px;
			vertical-align: middle;
		}
		p {
			margin-top: 12px;
			display: inline-block;
			vertical-align: middle;
			align-self: center;
			align-items: center;
		}
	}
	button {
		background-color: transparent;
		border: none;
	}
}

#allInstrumentsNewFile,
#allStudentsNewFile {
	width: 100%;
}

#filesHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	#headerName {
		h2 {
			display: inline-block;
		}
		h4 {
			display: inline-block;
		}
	}
}

.uploadForm {
	h2 {
		margin-top: 20px;
	}
	p:nth-of-type() {
		font-weight: 700;
		margin-top: 20px;
	}
	input[type="submit"] {
		float: right;
	}
}

#studentUploadFileContainer > button,
#uploadFileContainer > button,
#createFolderContainer > button {
	margin-top: 10px;
}

#fileName {
	font-size: 0.75rem;
}

#folderName {
	margin-left: 6px;
}

#usersDiv {
	z-index: 1;
	background-color: #f6f6f6;
	border: 1.5px solid black;
}

#shareSearchDiv {
	border: 1.5px solid #999;
	background-color: #ddd;
	button {
		border: none;
		background-color: $main-green;
		color: $white;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		padding: 0.4em 0.65em;
		font-size: 0.4em;
		border-radius: 0.25em;
		font-family: inherit;
		margin-left: 5px;
		margin-right: 5px;
		width: 100px;
	}
}

.firstQuestionDiv {
	margin-bottom: 40px;
}

.questionDiv {
	margin-top: 40px;
	margin-bottom: 20px;
	overflow: hidden;
}

.question {
	font-weight: 700;
	line-height: 1.5em;
	font-size: 1rem;
}

#editContainer {
	input[type="submit"] {
		float: right;
	}
}

#displayName {
	padding: 7px 5px;
	width: 60%;
}

.uploadGrid {
	position: absolute; /* Sit on top of the page content */
	display: inline-grid; /* Hidden by default */
	top: 105px;
	right: 65px;
	background-color: $white; /* Black background with opacity */
	border: 1.5px solid black;
	border-radius: 2%;
	z-index: 2;
	.uploadGridItem {
		margin-top: 5px;
		margin-bottom: 5px;
		p {
			display: inline-block;
			color: #000;
			font-weight: 400;
			font-family: inherit;
			font-size: 1em;
			line-height: 1.5;
			align-self: center;
			vertical-align: middle;
			margin-right: 20px;
		}
		img {
			display: inline-block;
			height: 35px;
			width: 35px;
			margin-left: 20px;
			margin-right: 15px;
			align-self: center;
			vertical-align: middle;
		}
	}
	.uploadGridItem:hover {
		background: #ddd;
	}
}

@media (max-width: 1150px) {
	.uploadGrid {
		top: 80px;
		right: 35px;
	}
}

@media (max-height: 575px) {
	.upperLeftButtons {
		margin-bottom: 5px;
	}
}

@media (max-width: 575px) {
	.upperLeftButtons {
		margin-bottom: 5px;
	}
	.uploadGrid {
		top: 72px;
		right: 20px;
	}
}

@media (max-width: 370px) {
	.uploadGrid {
		top: 104px;
		right: 20px;
	}
}

@media (max-width: 315px) {
	.uploadGrid {
		top: 143px;
		right: auto;
	}
}

.upperLeftButtons {
	border: none;
	display: inline-flex;
	align-items: center;
	max-width: 335px;
}

@media (max-width: 450px) {
	.upperLeftButtons {
		max-width: 280px;
		margin-bottom: 0px;
		div,
		button {
			margin: 5px;
		}
	}
}

#uploadDiv {
	position: relative;
	display: inline-block;
	align-self: right;
	background-color: $main-green;
	border-radius: 50%;
	color: $white;
	font-weight: 400;
	text-align: center;
	padding: 0.4em 0.65em;
	font-size: 1em;
	line-height: 1.5;
	border-radius: 0.25em;
	font-family: inherit;
	margin-left: auto;
	margin-right: 0;
	vertical-align: middle;
	margin-left: 5px;
	margin-right: 5px;
	p {
		display: inline-block;
		color: $white;
		font-weight: 400;
		font-family: inherit;
		font-size: 1.1em;
		line-height: 1.5;
		align-self: center;
		vertical-align: middle;
	}
	img {
		display: inline-block;
		height: 20px;
		width: 20px;
		margin-left: 5px;
		align-self: center;
		vertical-align: middle;
	}
}

#editFileName {
	margin: 20px 0px;
	overflow-wrap: break-word;
	max-width: 100%;
	overflow-x: hidden;
	line-height: normal;
}

#folderSelectEdit {
	width: 360px;
	display: block;
	overflow: hidden;
	#chooseFolderButton {
		width: 350px;
		border: 0.5px $black solid;
		background-color: $lightGrey;
		color: $black;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		padding: 0.4em 5em;
		font-size: 0.4em;
		line-height: 1.5;
		border-radius: 0.25em;
		font-family: inherit;
		margin-left: 5px;
		margin-right: 5px;
	}
}

.shareLi {
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 5px;
	margin-top: 5px;
}

.shareName {
	display: inline-flex;
	font-weight: 700;
}
#addFileLoadingSection {
	.loadingContainer {
		margin: 3em;
		.loading {
			width: 3em;
			height: 3em;
			.loadingImg {
				width: 3em;
				height: 3em;
			}
		}
	}
}

.fileSubmit {
	@include greenButton;
	margin: 10px 0px;
	float: right;
}

.fileSubmit:disabled {
	background-color: grey;
	cursor: default;
}

.fileGoGreen {
	@include greenButton;
	align-self: flex-end;
	margin-top: 10px;
	cursor: pointer;
	float: right;
}
#userShareSearch {
	width: 65%;
}

.userEmail {
	display: inline-block;
}

::selection {
	color: $white;
	background: $main-green;
}

.wrapper {
	max-width: 450px;
	margin: 20px auto;
}

.wrapper .search-input {
	background: $white;
	width: 100%;
	border-radius: 5px;
	position: relative;
	box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.08);
}

.search-input input {
	height: 55px;
	width: 100%;
	outline: none;
	border: none;
	border-radius: 5px;
	padding: 0 30% 0 5%;
	font-size: 18px;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.search-input.active input {
	border-radius: 5px 5px 0 0;
}

.search-input .autocom-box {
	padding: 0;
	opacity: 1;
	pointer-events: none;
	max-height: 280px;
	overflow-y: auto;
}

.search-input.active .autocom-box {
	padding: 10px 8px;
	opacity: 1;
	pointer-events: auto;
}

.autocom-box li {
	padding: 8px 12px;
	width: 91%;
	border-radius: 3px;
	display: block;
}

.autocom-box li:hover {
	background: #efefef;
}

.search-input .icon {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 55px;
	width: 20%;
	text-align: center;
	line-height: 55px;
	font-size: 20px;
	margin-left: 10px;
	background-color: $main-green;
	color: $white;
	border-radius: 5%;
}

@media (max-width: 1150px) {
	.search-input .icon {
		width: 25%;
		font-size: 15px;
	}
}
