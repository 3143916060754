@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";

#manageCardsMain {
	.paymentMethods {
		display: flex;
		flex-wrap: wrap;
		li {
			padding: 15px;
			margin: 0px 20px 20px 0px;
			background-color: $grey;
			border-radius: 20px;
			font-weight: 700;
			button {
				border: none;
				border-radius: 10px;
				background-color: rgba(0, 0, 0, 0);
				color: $red;
				font-weight: 700;
				padding: 5px;
				margin-left: 40px;
			}
		}
	}
	.header {
		display: inline;
		h2 {
			display: inline;
		}
		.rightSideButtons {
			float: right;
		}
	}

	h2 {
		margin-left: 10px;
	}
	hr {
		width: 99%;
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

.hidden {
	display: none;
}

#newCardForm {
	#inputs,
	#billingInputs #nameInputs {
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding-right: 15px;

		//padding-left: 3%;
		div,
		li {
			margin-bottom: 2%;
			width: 100%;

			.divInput,
			input {
				box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
				padding: 10px 5px;
				@include roundCorners;
			}

			div,
			input {
				transition: all 0.5s ease;
				border: 1px solid #e6e6e6;
			}

			div.focused,
			input.focused {
				border-color: $main-blue;
			}
		}

		li.shortInput {
			.divInput,
			input {
				box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
			}

			div,
			input {
				width: 100%;
				transition: all 0.5s ease;
				border: 1px solid #e6e6e6;
			}

			div.focused,
			input.focused {
				border-color: $main-blue;
			}
		}
	}
}
