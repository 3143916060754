@import "../../../../scss/variables";
@import "../../../../scss/mixins";
@import "../../../../scss/grid";

#selectInstrumentDiv {
	hr {
		margin-top: 5px;
	}

	#selectInstrumentsButton {
		@include greenButton;
		margin-bottom: 0;
	}

	.selectGrid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 30px;
		grid-row-gap: 20px;

		.grid-item {
			display: inline-grid;
			text-align: center;
			background-color: $white;
			height: 50px;
			border: 1px solid #707070;
			border-radius: 10px;

			.withinGrid {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 100%;
			}
		}

		.selectedInstrument {
			background-color: #b4f0c0;
			border: 1px solid #000;
		}
	}
}

@media (max-width: 750px) {
	#selectInstrumentDiv {
		.selectGrid {
			grid-template-columns: 1fr 1fr;
		}
	}
}
