@import "../../scss/variables";

#stripeModal {
	text-align: center;
	#stripeBlurb2 {
		text-align: left;
	}
}

a.buttonLink {
	color: $white;
}

#stripeRecommendationList {
	margin-top: 20px;
	li {
		text-align: left;
		margin-left: 35px;
		list-style: decimal;
	}
}
