@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";

#instructorProfileHeader {
	display: flex;
	width: calc(100% - 60px);
	margin: 0 auto;

	img,
	div {
		padding: 20px 50px 20px 0;
	}
	.rightSideButtons {
		padding-right: 0;
	}
	img {
		width: 145px;
		height: 145px;
		object-fit: cover;
	}
	div:nth-of-type(1) {
		flex-grow: 1;
		h2 {
			line-height: 30px;
			display: inline-block;
			padding-right: 10px;
		}
		#messageInstructorProfileLink {
			align-items: center;
			color: $black;
			text-decoration: underline;
			white-space: nowrap;
		}
		#messageInstructorProfileLink,
		span {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		#messageInstructorProfileLink:after {
			content: "";
			display: block;
		}
		div span,
		span {
			display: flex;
			align-items: center;
		}
		div img,
		img {
			width: 20px;
			height: 20px;
			padding: 0px 20px 0px 0px;
			display: inline;
		}
	}
}
@media (max-width: 900px) {
	#instructorProfileHeader {
		width: 100%;
		.rightSideButtons {
			padding: 10px;
		}
		img,
		div {
			padding: 10px;
		}
		img {
			width: 100px;
			height: 100px;
		}
	}
}

@media (max-width: 600px) {
	#instructorProfileHeader {
		display: flex;
		flex-wrap: wrap-reverse;
		#instPFP {
			position: relative;
			display: inline;
			float: left;
		}
		.rightSideButtons {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			button {
				margin: 2px;
			}
		}
		// #messageInstructorProfileLink, h2, span {
		//     margin-left: 110px;
		// }

		// h2 {
		//     margin-right: 100px;
		// }
	}
}
