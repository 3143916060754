@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";

#instructorProfile {
	overflow-y: auto;
	display: block;
	.underlineLink {
		text-decoration: underline;
	}

	#betweenInstructorHeaderBody {
		width: calc(100% - 255px);
		margin-right: 30px;
		border: solid rgb(220, 220, 220) 1px;
	}
	#instructorProfileBody {
		display: flex;
		padding-left: 235px;
		padding-top: 20px;
		div:nth-of-type(1) {
			flex-grow: 1;
			#instructorAbout {
				margin-bottom: 40px;
				width: 90%;
				p {
					white-space: pre-wrap;
				}
			}
		}
		#upcomingLessonsContainer {
			margin-right: 30px;
			border-left: solid rgb(220, 220, 220) 2px;
			padding-left: 20px;
			height: 100%;
			h3 {
				margin-bottom: 10px;
			}
			ul {
				width: 360px;
				li:nth-of-type(n + 6) {
					//Hides lessons after the fifth one
					display: none;
				}
				li:nth-of-type(odd) {
					background-color: #f2f2f2;
				}
				li {
					padding: 15px;
					border-radius: 5px;
					span {
						display: inline-block;
					}
					span:nth-of-type(1) {
						//Date
						width: 110px;
					}
					span:nth-of-type(2) {
						//Time
						width: 160px;
					}
					span:nth-of-type(3) {
						//Price
						width: 40px;
						font-weight: bold;
					}
					img {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
}

@media (max-width: 1181px) {
	#instructorProfile {
		#instructorProfileHeader {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			div:nth-of-type(1) {
				h2 {
					line-height: 2rem;
					margin-bottom: 10px;
					display: block;
				}
				#messageInstructorProfileLink {
					display: flex;
				}
			}
		}
		#betweenInstructorHeaderBody {
			width: calc(100% - 60px);
		}
		#instructorProfileBody {
			div:nth-of-type(1) {
				#instructorAbout {
					width: 100%;
				}
			}
			padding-left: 30px;
		}
	}
}

@media (max-width: 900px) {
	#instructorProfile {
		#betweenInstructorHeaderBody {
			width: calc(100% - 20px);
			margin-right: auto;
		}
		#instructorProfileBody {
			display: block;
			width: 100%;
			padding-left: 0px;
			margin-right: 0px;
			#instructorInstruments {
				margin-bottom: 40px;
			}
			#upcomingLessonsContainer {
				border-left: none;
				padding-left: 0;
				ul {
					width: 100%;
					display: block;
					li {
						width: 100%;
						display: flex;
						justify-content: space-around;
						span:nth-of-type(n) {
							width: auto;
						}
					}
				}
			}
		}
	}
}
