@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";
#findLessonContainer {
	position: relative;

	.header {
		margin-bottom: 5px;
		display: flex;
		justify-content: space-between;
	}

	#findLesson {
		display: inline;
		background-attachment: black;
		margin-bottom: 5px;
	}
}
