@import "fonts";
@import "reset";
@import "variables";
@import "classes";
@import "mixins";
@import "grid";

html {
	font-family: "Roboto", "Sans-Serif";
	background-color: $lightGrey;
	color: $black;
}

iframe {
	display: block;
	width: 300px;
	margin: auto;
}

.amzn-ad-container {
	width: 300px;
}

a {
	color: $main-green;
	text-decoration: none;
	font-size: 1rem;
}

a:hover,
button,
input[type="submit"] {
	cursor: pointer;
}

hr {
	border-radius: 10px;
	border: 0.5px solid $black;
	background-color: $black;
}

//SETS FONT SIZES AND STYLES TO BE USED SITE WIDE

html {
	font-size: 15px;
}

@media (max-width: 450px) {
	html {
		font-size: 12px;
	}
}

p,
li,
input,
button,
select,
label {
	line-height: 1.5em;
	font-size: 1rem;
}

li {
	line-height: 1.7em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
}

h1 {
	font-size: 3rem;
	line-height: 2.75rem;
}
h2 {
	font-size: 2rem;
	line-height: 2.5rem;
	color: $black;
}
h3 {
	font-size: 1.75rem;
	line-height: 2.25rem;
}
h4 {
	font-size: 1.5rem;
	line-height: 2rem;
}
h5 {
	font-size: 1.25rem;
}
h6 {
	font-size: 1rem;
}
em {
	font-style: italic;
}

//SETS FONT SIZES AND STYLES TO BE USED SITE WIDE

.hamburger {
	//Hides nav pull out on load
	display: none;
	position: absolute;
	width: 30px;
	top: 15px;
	right: 45px;
}

input[type="submit"] {
	@include greenButton;
}

input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus {
	border-color: $main-blue;
}

nav {
	width: calc(100vw - 40px);
	max-width: 100%;
	padding: 10px;
	height: 50px;

	position: absolute;
	#nav-logo {
		//Top left logo
		height: 35px;
	}
	ul {
		//Main navigation
		float: right;
		li {
			display: inline-block;
			position: relative;
			top: -35px;
			a {
				color: $black;
			}
		}
		li:not(:last-child):after {
			//Adds the vertical lines between the navigation links
			content: " | ";
			font-size: 1.15rem;
			margin: 0px 5px;
			color: $black;
		}
	}
}

footer {
	padding: 50px 0px 20px;
	margin-bottom: -50px;
	@include centerObject;
	background-color: $black;
	color: white;
	a {
		color: white;
	}
	img {
		width: 35px;
		margin: 10px;
	}
	#footerLinks {
		ul {
			display: inline-block;
			margin: 0px 30px;
			text-align: left;
		}
	}
}

input[type="text"],
input[type="search"],
input[type="number"],
textarea {
	@include roundCorners;
	border: solid 1px $black;
	color: $black;
	padding: 10px 5px;
	max-width: calc(100% - 15px);
}

input[type="text"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
textarea:focus {
	//Active form field all
	color: black;
	border-radius: 10px;
	outline-color: $main-blue;
}

input[type="text"]::placeholder,
input[type="search"]::placeholder,
input[type="number"]::placeholder,
textarea::placeholder {
	//Active form field all
	color: rgba(171, 171, 171, 0.719);
}

textarea {
	font-family: inherit;
}

input[type="checkbox"] {
	display: inline;
}

//custom file upload buttons
.uploadFileWrapper {
	overflow: hidden;
	display: inline-block;
}
.fileUploadButton {
	border: 1px solid $black;
	color: $black;
	background-color: $lightGrey;
	padding: 5px 20px;
	@include roundCorners;
	font-size: 1rem;
}
.uploadFileWrapper input[type="file"] {
	font-size: 1rem;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}
//
// Format needed to make buttons look nice
//
//<div class="uploadFileWrapper">
//    <button class="fileUploadButton">Upload File</button>
//    <input id='files' name='files' type='file' accept='image/*, video/*, audio/*, .pdf, .jpg, .doc, .docx'>
//</div>
//
//custom file upload buttons

select {
	appearance: none;
	text-align-last: right; //Right aligns text in drop down menu
    padding: 5px;
	border: none;
	background-color: transparent;
	color: $black;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwMCAxMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICA8cGF0aCBmaWxsPSJncmF5IiBkPSJNMTAsMjMyLjdsNDkwLDUzNC41bDQ5MC01MzQuNUgxMHoiIC8+DQo8L3N2Zz4=);
	background-repeat: no-repeat;
	background-position: right;
	background-size: 10px;
	option {
		direction: rtl; //Right aligns text in drop down menu
	}
}

select + input {
	//Search files to right
	float: right;
}

#select + input + hr {
	width: 310px;
	margin-left: 0px;
}

#copyLink {
	@include greenButton;
	margin: 0 0 0 0;
}

::selection {
	color: $white;
	background: $main-green;
}

.wrapper {
	max-width: 450px;
	margin: 20px auto;
}

.wrapper .search-input {
	background: $white;
	width: 100%;
	border-radius: 5px;
	position: relative;
	box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.08);
}

.search-input input {
	height: 55px;
	width: 100%;
	outline: none;
	border: none;
	border-radius: 5px;
	padding: 0 0 0 5%;
	font-size: 18px;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.search-input.active input {
	border-radius: 5px 5px 0 0;
}

.search-input .autocom-box {
	padding: 0;
	opacity: 1;
	pointer-events: none;
	max-height: 280px;
	overflow-y: auto;
}

.search-input.active .autocom-box {
	padding: 10px 8px;
	opacity: 1;
	pointer-events: auto;
}

.autocom-box li {
	padding: 8px 12px;
	width: 91%;
	border-radius: 3px;
	display: block;
}

.autocom-box li:hover {
	background: #efefef;
}

.search-input .icon {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 55px;
	width: 20%;
	text-align: center;
	line-height: 55px;
	font-size: 20px;
	margin-left: 10px;
	background-color: $main-green;
	color: $white;
	border-radius: 5%;
}

@media (max-width: 1150px) {
	.search-input .icon {
		width: 25%;
		font-size: 15px;
	}
}

#lessonInfo {
	button {
		margin-top: 0px;
	}
}

.errMsg {
	max-width: 600px;
	margin: 0px auto 160px;
	padding: 20px;
	text-align: center;

	position: relative;
	top: 80px;

	@include dashBoxShadows;
	@include roundCorners;
	h3,
	p {
		margin: 20px auto;
	}
	p:nth-of-type(1) a {
		font-weight: 600;
	}
	p:nth-of-type(2) a {
		@include greenButton;
	}
}

// @import "staticSite";

// @import "indexSpecific";
@import "dashMain";
@import "dashInstructorHome";
@import "files";
@import "settings";
// @import "blog";
@import "dashStudentHome";
@import "instructorList";
@import "intructorLessonList";
@import "stripeOnboard";
@import "instOnboard";
@import "messages";
@import "choose";
@import "lesson";
// @import 'editAvailables';
@import "history";
// @import "indexSmall";
@import "schedule";
@import "profile";
// @import "login";
// @import 'timePicker';
