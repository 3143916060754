
@import '../../scss/variables';

#errorContainer {
    overflow-y: none;
    overflow: hidden;
    height: 100vh;
    background-image: linear-gradient(to right, hsl(121, 42%, 54%), $main-blue);
    margin: 0px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    #errorContent {
        width: 1200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        #gramophone {
            width: 150%;
            margin-top: 8%;
        }
        #errorButton {
            padding: 10px 40px;
            width: auto;
            font-size: 18px;
            color: black;
            display: inline-block;
            margin-bottom: 18%;
            z-index: 1;
            top: -40px;
            left: 15px;
        }
        #record {
            margin-top: 20%;
            width: 50%;
        }
    }
}

@media(max-width: 1200px) {

    #errorContainer {
        #errorContent {
            flex-direction: column-reverse;
            #gramophone {
                display: none;
            }
            #errorButton {
                top: 0px;
                left: 0px;
                margin-top: 20%;
            }
            #record {
                margin-top: 0px;
                width: 75%;
            }
        }
    }
}

@media(max-width: 800px) {

    #errorContainer {
        #errorContent {
            #record {
                width: 100%;
            }
        }
    }

}