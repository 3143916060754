@import '../../../scss/variables';
@import '../../../scss/mixins';

.blackButton {
    border: none;
    background-color: $black;
    color: $white;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    padding: 0.4em 0.65em;
    font-size: 1.1em;
    line-height: 1.5;
    border-radius: 0.25em;
    font-family: inherit;
    margin-left: 5px;
    margin-right: 5px;
    a {
        color: $white;
    }
}