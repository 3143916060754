@import "variables";
@import "mixins";

// #instDashHeader {
//     display: flex;
//     align-items: center; //Centers Vertical
//     @include BGGradient;
//     color: $white;
//     h2 {
//         color: $white;
//     }
//     #instWelcome {
//         margin-right: auto;
//     }
//     #miniStats {
//         text-align: center;
//     }
// }

// #mainPFP {
//     justify-self: flex-start;

//     img {
//         height: $pfpRadius;
//         width: $pfpRadius;

//         object-fit: cover;
//     }
// }

#lessons {
	hr + p {
		float: left;
		padding: 10px 0px;
	}
	p + p {
		text-align: right;
		padding: 10px 0px;
	}
	p:nth-last-of-type(1) {
		float: right;
	}
}

@media (max-width: 1150px) {
	#upcomingLessons,
	#notifications {
		@include dashBoxShadows;
		@include roundCorners;
		margin: 15px auto;
		padding: 10px;
	}
}
