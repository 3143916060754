@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";

#loadingElement {
	height: 100%;
	position: relative;
	margin: auto;

	.loadingSection {
		.loadingContainer {
			height: 100%;
			display: flex;
			justify-content: center;
			flex-direction: column;

			.loading {
				margin: 0 auto;
			}
		}
	}
}
