@import '../../../scss/variables';
@import '../../../scss/mixins';

#studentDashHeader {
    overflow: auto;
    @include BGGradient;
    color: $white;
    h2, a {
        color: $white;
    }
    hr {
        border-color: $white;
        background-color: $white;
    }
    a {
        font-weight: 700;
    }

    div {
        text-align: center;
    }
    #sendMessage {
        font-size: inherit;
        text-decoration: underline;
    }

    .bareButton {
        font-weight: 700;
        color: $white;
    }
    
    #headerFindLesson {
        margin: auto;
    }
}