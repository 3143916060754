@import '../../../scss/variables';
@import '../../../scss/mixins';

.timePickerDropdown {
    filter: drop-shadow(3px 3px 3px $drop-shadow);
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    background-color: $white;
    margin-top: 5px;
}