@import '../../../../scss/variables';
@import '../../../../scss/mixins'; 
@import '../../../../scss/grid';
@import '../../../../scss/classes';

.Conversation {
    display: flex;
    padding: 5px 0px;

    img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    div {
        display: inline-flex;
        align-items: center;
        margin-left: 20px;
        flex-grow: 1;
    }
}


span {
    a {
        color: $black;
    }
    hr {
        border: 0.5px solid #707070;
        display: block;
        margin-right: 0;
        opacity: 0.2;
    }
}