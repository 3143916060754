@import 'variables';
@import 'mixins';

#mainStudentPFP {
    img {
        width: calc($pfpRadius / 1.4);
        height: calc($pfpRadius / 1.4);
        
        object-fit: cover;
    }
}

// .studentHome > .gridHeader {
//     overflow: auto;
//     @include BGGradient;
//     color: $white;
//     h2, a, button {
//         color: $white;
//     }
//     hr {
//         border-color: $white;
//         background-color: $white;
//     }
//     a, button {
//         font-weight: 700;
//     }
// }

// #studentDashHeader {
//     div {
//         text-align: center;
//     }
//     #sendMessage {
//         font-size: inherit;
//         text-decoration: underline;
//     }
//     #headerFindLesson {
//         @include dashBoxShadows;
//         @include roundCorners;
//         width: 135px;
//         position: relative;
//         color: $black;
//         padding: 5px 20px;
//         margin: auto;
//         display: block;
//         //height: 30px;
//         cursor: pointer;
//     }
// }

#mainStudentPFP + h4 {
    display: inline;
}
#mainStudentPFP + h4 + p {
    display: inline;
}

#findLessonButton {
    @include greenButton;
    margin: 0 0 0 0;
}


@media (max-width: 1150px) {
    #studentDashHeader {
        border-radius: $cornerRadius;
        padding: 10px;
        #instWelcome {
            display: inline-block;
        }
    }
    #upcomingLessonsDiv, #notificationsDiv {
        @include dashBoxShadows;
        @include roundCorners;
        margin: 15px auto;
        padding: 10px;
    }
}

@media (max-width: 700px) {
    #mainStudentPFP {
        display: none;
    }
}