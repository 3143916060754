@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";

#instructorLessonList {
	#lessonListContainer {
		width: calc(100% - 60px);
		margin: 0 auto;
	}
	ul {
		hr {
			width: 100%;
		}
		div {
			display: contents;
			hr {
				width: 100%;
			}
		}
		li {
			display: inline-block;
			width: 200px;
			text-align: center;

			background-color: $grey;
			padding: 10px;
			margin: 10px 20px 10px 0px;
			border-radius: 10px;
			p:nth-of-type(1),
			p:nth-of-type(2) {
				color: $black;
			}
			p:nth-of-type(1) {
				font-weight: 700;
			}
		}
	}
}

@media (max-width: 900px) {
	#instructorLessonList {
		#lessonListContainer {
			width: 95%;
			margin: 0 auto;
		}
		ul {
			p {
				margin-bottom: 6px;
			}
		}
	}
}
// 	#lessonListHeader {
// 		flex-direction: column-reverse;
// 		#lessonListButtons {
// 			display: flex;
// 			flex-direction: column-reverse;
// 			margin-bottom: 20px;
// 			button {
// 				display: block;
// 			}
// 		}
// 	}
// }
