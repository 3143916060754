@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";

.instDashBody,
.studentDashBody {
	background-image: url("../../images/dashBG.png");
	background-size: cover;
	position: center;
}

#nav-logo-dash {
	width: 60px;
	height: auto;
	margin-left: 7.5px; //Centers logo in gradient
	margin-top: 15px;
}

#mainPFP,
#mainStudentPFP {
	//margin-left: 20px;
	margin-right: 20px;
	float: left;
	img {
		border: solid $white 2px;
		border-radius: $pfpRadius;
	}
}

a#findLessonButton {
	@include greenButton;
	margin: 0 auto;
}

#futureLessons {
	#futureLessonListObject {
		.outDivLinkButton {
			float: right;
			margin-top: 15px;
			margin-bottom: 0px;
		}
	}
}

.subNav {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	width: $dashNavWidth;
	height: 100vh;
	@include dashBoxShadows();

	transition: width 0.5s ease; //Slides in and out when hovered over

	.smallListExpand {
		display: none;
	}

	li > a {
		color: $black;
		display: block;
		margin: 5px 0px;
		line-height: 0px;
		img {
			margin: 7px 0px 7px 23px;
			width: 25px;
			height: 25px;
		}
		p {
			font-size: 0px;
			visibility: hidden;
			opacity: 0;
			transition: opacity 0.75s;
		}
	}
	li:nth-last-of-type(1) {
		display: none;
	}
}
@media (min-width: 575px) and (min-height: 575px) {
	.subNav:hover {
		width: 250px;
		p {
			font-size: 1rem;
			position: relative;
			visibility: visible;
			opacity: 1;
			top: -18px; //Aligns text with images in side nav
			left: 75px;
			line-height: 0px; //Fixes spacing
		}
	}
}

.notificationObject {
	display: flex;
	width: 100%;
	justify-content: space-between;

	.notificationContent {
		width: 75%;
	}
	.notificationIconWrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	a {
		margin: auto;
	}
	.notificationLinkText {
		color: $main-green;
		cursor: pointer;
	}
}

.notificationsHeader {
	display: flex;
	justify-content: space-between;
	padding-bottom: 1px;
	h3 {
		padding-top: 0.2rem;
	}
}

.notificationHr {
	border-color: rgb(200, 200, 200);
	background-color: rgb(200, 200, 200);
}

@media (max-width: 575px), (max-height: 575px) {
	#notifications {
		margin-bottom: 70px;
	}
	.subNav:hover {
		z-index: 99999;
		width: 100vw;
	}
	.subNav {
		all: initial; //Resets subnav properties

		background-color: $white;
		box-shadow: 0px -3px 3px $black;
		border-top: solid 1px $black;
		position: fixed;
		bottom: 0;
		width: 100vw;
		.dashList {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;

			width: calc(65 * 5px);
			margin: 0 auto;
			margin-bottom: -79px;

			transition: margin ease-out 150ms;

			.smallListExpand {
				display: inline;
				background-color: $main-blue;

				border: solid 1px #909090;
				border-radius: 69420px;

				width: 45px;
				height: 45px;

				margin: 0 9px;

				position: relative;
				bottom: 30px;
				img {
					height: 45px;
					width: 45px;
					filter: brightness(0) invert(1);
				}
			}
			.smallListExpand:hover {
				cursor: pointer;
			}
			li > a {
				p {
					visibility: visible;
					opacity: 1;
					font-size: 11px;
					text-align: center;
					font-family: "Roboto", "Sans-Serif";
				}
			}
		}

		#logo-background {
			display: none;
		}
		li > a {
			img {
				margin: 5px 20px;
			}
		}
	}

	#futureLessons {
		#futureLessonListObject {
			.outDivLinkButton {
				padding-top: 5px;
				padding-bottom: 5px;
				margin-top: 20px;
			}
		}
	}
	hr {
		margin-top: 0px;
	}
	.icon {
		width: 25px;
		height: 25px;
	}
}
