@import '../../../scss/variables';
@import '../../../scss/mixins';

.whiteButton {
    @include dashBoxShadows;
    @include roundCorners;
    width: 175px;
    position: relative;
    color: $black;
    padding: 5px 20px;
    margin: 10px;
    border: none;
    display: block;
}