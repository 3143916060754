@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";

.loadingSection {
	height: 40vh;
	.loadingContainer {
		height: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;

		.loading {
			margin: 0 auto;
		}

		.loadingImg,
		.loading {
			width: 2em;
			height: 2em;
		}
	}
}

#availableForm {
	line-height: 10%;
	display: block;

	#start,
	#end {
		margin-right: 5px;
		border-radius: 10px;
		border-width: 1px;
		width: 15%;
	}

	#availableInfo {
		display: block;
		margin-bottom: 5%;

		.popUpTime {
			display: inline-block;
			padding-bottom: 7px;
		}

		.shorterTimeButton {
			display: block;
			margin-top: 0.5%;

			button {
				background: none;
				border: none;
				padding: 0;
				font: inherit;
				outline: inherit;
				font-size: small;
				height: 20px;
                color: $main-blue;
				width: max-content;
			}

			float: right;
		}
	}

	#availableLocation {
		margin-bottom: 5%;

		#availableLocationHeader {
			font-weight: bold;
			font-size: large;
		}
	}

	#availableInstruments {
		#availableInstrumentsHeader {
			font-weight: bold;
			font-size: large;
		}

		li {
			display: flex;
			align-items: center;

			input {
				height: 10px;
				width: 10px;
				margin: 0 5px 0 0;
			}

			label {
				font-size: small;
			}
		}
	}

	button {
		@include greenButton;
		float: right;
		margin-right: 2%;
		margin-bottom: 0px;
	}

	button.deactivated {
		@include greenButton;
		background-color: gray;
		cursor: default;
		float: right;
		margin-right: 2%;
		margin-bottom: 0px;
	}
}
