@font-face {
    font-family: 'Roboto';
    src: url('./fonts/roboto-bold.woff2') format('woff2'),
         url('fonts/roboto-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-medium.woff2') format('woff2'),
         url('fonts/roboto-medium.woff') format('woff');
    font-weight: medium;
    font-style: normal;

}

@font-face {
    font-family: 'Roboto';
    src: url('fonts/roboto-regular.woff2') format('woff2'),
         url('fonts/roboto-normal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}