@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/grid";

#settingsMain,
#settingsNotif {
	input[type="submit"] {
		display: block;
		margin: 20px 0px 0px auto;
	}
}

#settingsMain {
	form {
		display: block;
		.submitWrapper {
			display: block;
			margin: 20px 0px 0px auto;
		}
		#manage-cards-link {
			margin-top: 10px;
		}
	}

	.selectInstrumentsButtonDiv {
		display: flex;
		flex-direction: column;
		width: 25%;
	}

	#selectInstrumentsButton {
		@include greenButton;
		margin-bottom: 0px;
		margin-top: 5px;
		display: block;
	}

	a {
		display: block;
		margin-top: 45px;
	}

	textarea {
		width: 100%;
		min-height: 80px;
	}

	input:nth-of-type(2) {
		margin: 5px 0px;
	}

	h2 {
		display: inline-block;
	}

	#displayEmail,
	#displayEmail + label {
		margin-top: 45px;
		display: inline;
		font-weight: 100;
	}

	#displayEmail + label {
		margin-left: 10px;
	}
}

.settingsHeader {
	img {
		height: 100px;
		width: 100px;
		border: solid $black 2px;
		border-radius: $pfpRadius;

		object-fit: cover;
	}

	img,
	h2 {
		display: inline-block;
		vertical-align: middle;
	}

	h2 {
		margin-left: 10px;
	}
}

.sr-main {
	text-align: center;
	@include roundCorners;
	@include dashBoxShadows;
	height: 200px;
}

#selectInstrumentDiv {
	hr {
		margin-top: 5px;
	}

	#selectInstrumentsButton {
		@include greenButton;
		margin-bottom: 0;
	}

	.selectGrid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 30px;
		grid-row-gap: 20px;

		.grid-item {
			display: inline-grid;
			text-align: center;
			background-color: $white;
			height: 50px;
			border: 1px solid #707070;
			border-radius: 10px;

			.withinGrid {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 100%;
			}
		}

		.selectedInstrument {
			background-color: #b4f0c0;
			border: 1px solid #000;
		}
	}
}

#settingsMain {
	padding: 10px;

	h2 {
		margin-bottom: 10px;
	}
}

#settingsNotif {
	padding: 10px;
	margin-bottom: 120px;

	form {
		display: block;
	}
}

.custom-file-input {
	color: transparent;
}

.custom-file-input::-webkit-file-upload-button {
	visibility: hidden;
}

.custom-file-input::before {
	content: "Upload profile picture";
	font-family: "Roboto", "Sans Serif";
	color: black;
	display: inline-block;
	// background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
	border: 1px solid #999;
	border-radius: 3px;
	padding: 5px 8px;
	outline: none;
	white-space: nowrap;
	// -webkit-user-select: none;
	cursor: pointer;
	font-size: 10pt;
}

.custom-file-input:hover::before {
	border-color: black;
}

.custom-file-input:active {
	outline: 0;
}

#newCardLink {
	@include greenButton;
	position: absolute;
	top: 5px;
	right: 10px;
}

@media (max-width: 1300px) {
	#settingsMain {
		.selectInstrumentsButtonDiv {
			width: 40%;
		}
	}
}

@media (max-width: 1150px) {
	#settingsMain,
	#settingsNotif {
		@include dashBoxShadows;
		@include roundCorners;
		margin: 15px auto;
	}

	#settingsMain {
		height: auto;
	}

	#settingsNotif {
		margin-bottom: 100px;
	}
}

@media (max-width: 750px) {
	// #settingsMain {
	// 	height: 920px;
	// }

	#settingsMain {
		.selectInstrumentsButtonDiv {
			width: 50%;
		}
	}
}
