#loadingPage {
	#loadingElement {
		height: auto;
	}
	position: fixed; /* Sit on top of the page content */
	display: flex; /* Hidden by default */
	width: 100vw; /* Full width (cover the whole page) */
	height: 100vh; /* Full height (cover the whole page) */

	justify-content: center;
	align-items: center;

	top: 0;
	left: 0;
}
