@import 'variables';
@import 'mixins'; 

#noteForm {
    textarea {
        width: 98%;
        max-width: 98%;
    }
}

pre {
    max-width: 98%;
    white-space: pre-wrap;
}