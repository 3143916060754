.sendingMessage {
    display: flex;
    justify-items: flex-end;
    p {
        background-color: #ACF7B3;
        margin-left: auto;
    }
}

.receivingMessage {
    p {
        background-color: #D3D3D3;
    }
}

.sendingMessage, .receivingMessage {
    p {
        display: inline-block;
        max-width: 66vw;
        padding: 5px 10px;
        border-radius: 5px;
        margin: 5px 0 5px auto;
    }
}