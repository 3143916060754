@import "mixins";

@media (max-width: 1150px) {
	.gridContainer {
		display: block;
		height: auto;
		margin: 25px 25px 25px 100px;
		.fullScreen {
			overflow-y: auto;
			height: calc(100dvh - 120px);
		}
	}
}

@media (max-width: 575px), (max-height: 575px) {
	.gridContainer {
		margin: 10px;
	}
}

@media (min-width: 1150px) {
	.gridContainer {
		width: 100vw;
		height: 100dvh;
		display: grid;
		grid-template-columns: 100px 1fr 1fr 1fr 1fr 1fr 1fr 25px;
		grid-template-rows: 0px 60px 60px 1fr 1fr 1fr 1fr 1fr 1fr 26px;

		@media (max-height: 575px) {
			grid-template-columns: 0px 100px 1fr 1fr 1fr 1fr 1fr 1fr 50px;
		}

		column-gap: 50px;
		row-gap: 50px;
	}

	.gridHeader {
		//background-color: red;
		grid-column-start: 2;
		grid-column-end: -2;
		grid-row-start: 2;
		grid-row-end: 4;
		@include dashBoxShadows;
		@include roundCorners;
		@include fill;
	}

	.gridLeftMainUnderHeader {
		//background-color: blue;
		grid-column-start: 2;
		grid-column-end: 6;
		grid-row-start: 4;
		grid-row-end: -2;
		@include dashBoxShadows;
		@include roundCorners;
		@include fill;

		overflow-y: auto;
	}

	.gridRightUnderHeader {
		//background-color: green;
		grid-column-start: 6;
		grid-column-end: -2;
		grid-row-start: 4;
		grid-row-end: -2;
		@include dashBoxShadows;
		@include roundCorners;
		@include fill;

		overflow-y: auto;
	}

	.gridTallLeftMain {
		//background-color: red;
		grid-column-start: 2;
		grid-column-end: 6;
		grid-row-start: 2;
		grid-row-end: -2;
		@include dashBoxShadows;
		@include roundCorners;
		@include fill;

		overflow-y: auto;
	}

	.gridTallRight {
		//background-color: green;
		grid-column-start: 6;
		grid-column-end: -2;
		grid-row-start: 2;
		grid-row-end: -2;
		@include dashBoxShadows;
		@include roundCorners;
		@include fill;

		overflow-y: auto;
	}

	.fullScreen {
		//background-color: red;
		grid-column-start: 2;
		grid-column-end: -2;
		grid-row-start: 2;
		grid-row-end: -2;
		@include dashBoxShadows;
		@include roundCorners;
		@include fill;

		overflow-y: auto;
	}
}
